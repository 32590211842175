import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getPayments: createCancelTokenHandler('getPayments'),
  getPaymentActivitiesLog: createCancelTokenHandler('getPaymentActivitiesLog')
}

export function getPayments (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/payment${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPayments'].handleRequestCancellation().token
  })
}

export function getPayment (id) {

  return axios({
    url: `v1/admin/treasury/payment/${id}`,
    method: 'get'
  })
}

export function printPayment ( filters = {}, sorts = []) {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/payment?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getPaymentActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/treasury/payment/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPaymentActivitiesLog'].handleRequestCancellation().token
  })
}

export function insertPayment (payment) {

  return axios({
    url: 'v1/admin/treasury/payment',
    data: payment,
    method: 'post'
  })
}

export function deletePayment (id) {
  return axios({
    url: `v1/admin/treasury/payment/${id}`,
    method: 'delete'
  })
}

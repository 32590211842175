<template>
  <div class="u-input custom-datepicker-input" :class="[{'dir-ltr': !$vs.rtl}]">
    <label class="u-input-box" :class="[{'is-not-valid': !data.isValid || notValid, 'disabled': disabled}, classes || {}]" :style="getStyles">
<!--      <span class="input-label" :class="{'is-focus': data.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>-->

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="u-input-label text-subtitle" :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <input type="text"
             :id="id"
             :class="[{'disabled': disabled}, {'has-clear': clearable && data.value.length > 0}]"
             :style="inputStyles"
             @input="handleInputValue"
             @click="$emit('click')"
             @contextmenu="handleContextMenuClicked($event)"
             @keydown.enter="handleKeydownEnter"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             :autocomplete="autoComplete"
             :name="Math.random()"
             v-model="data.value">
      <div v-if="clearable && data.value.length > 0" class="clearable" @click="handleClearInput">
        <custom-icon icon="TIMES" color="danger" />
      </div>
    </label>
    <custom-datepicker
        :format="datePickerFormat.replace(' ', ' - ')"
        :type="type"
        :min="min"
        :max="max"
        :auto-submit="autoSubmit"
        v-model="data.value"
        @input="handleInputValue" />

    <span class="u-input-errors" v-if="!data.isValid && data.value.length > 0" >
      {{ invalidText || $t('customDatePickerInput.invalidText') }}
    </span>
  </div>
</template>

<script>
import CustomIcon from "../customIcon/customIcon";
import {
  addDateSlash,
  addDateTimeSlash,
  addTimeColon,
} from "../../assets/js/functions";
import CustomDatepicker from "@/components/customDatepicker/customDatepicker.vue";
export default {
  name: 'customDatePickerInput',
  components: {CustomDatepicker, CustomIcon},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    rtl: {
      type: Boolean,
      default: () => { return false }
    },
    id: {
      type: String,
      default: () => { return '' }
    },
    type: {
      type: String,
      default: () => { return 'date' }
    },
    inputFormat: {
      type: String,
      default: () => { return '' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    clearable: {
      type: Boolean,
      default: () => { return false }
    },
    autoComplete: {
      type: Boolean,
        default: () => { return false }
    },
    autoSubmit: {
      type: Boolean,
        default: () => { return false }
    },
    label: {
      type: String,
      default: () => { return '' }
    },
    placeholder: {
      type: String,
      default: () => { return '' }
    },
    labelPlaceholder: {
      type: String,
      default: () => { return '' }
    },
    labelPosition: {
      type: String,
      default: 'outline'
    },
    align: {
      type: String,
      default: () => { return 'left' }
    },
    isEmpty: {
      type: Boolean,
      default: () => { return false }
    },
    notValid: {
      type: Boolean,
      default: () => { return false }
    },
    regex: {
      type: RegExp,
      default: () => { return null }
    },
    invalidText: {
        type: String,
        default: () => { return '' }
    },
    value: {
      type: Object,
      default () { return {} }
    },
    classes: {
      type: Object,
      default () { return {} }
    },
    styles: {
      type: Object,
      default () { return {} }
    },
    inputStyles: {
      type: String,
      default: () => ''
    },
    min: {
      type: String,
      default: () => ''
    },
    max: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      validateTimer: 0,
      data: {
        value: '',
        isValid: false
      },
      datePickerFormat: this.$localeValidator('moment.date')
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.inputFormat) {
      this.datePickerFormat = this.inputFormat.split('-').join('/')
    }
    if (this.isEmpty) {
      this.data.isValid = true
    } else {
      this.validateValue()
    }
  },
  methods: {
    handleInputValue () {
      // this.data.value = this.data.value.toString().replace(this.regex, '')

      let value = this.data.value

      if (this.type === 'date') {
        this.data.value = addDateSlash(value)
      } else if (this.type === 'time') {
        this.data.value = addTimeColon(value)
      } else if (this.type === 'datetime') {
        this.data.value = addDateTimeSlash(value)
      }

      this.validateValue('input')
      setTimeout(() => {
        let dateValue = this.data.value.split(' / ').join('/').split(' : ').join(':').split(' - ').join(' ')
        if (this.inputFormat.indexOf('-') > -1) {
          dateValue = dateValue.split('/').join('-')
        }
        this.$emit('input', {value: dateValue, isValid: this.data.isValid})
      }, 50)
    },
    validateValue () {
      if (this.regex !== null) {
        if (this.data.value.length === 0 && this.isEmpty) {
          this.data.isValid = true
        } else {
          this.data.isValid = this.regex.test(this.data.value.split(' / ').join('/').split(' : ').join(':').split(' - ').join(' '))
        }
      } else if (this.isEmpty) {
        this.data.isValid = true
      } else if (this.notValid) {
        this.data.isValid = false
      } else {
        this.data.isValid = this.data.value.toString().length > 0
      }
    },
    initValues () {
      // this.data.value = this.value.value
      if (this.inputFormat.indexOf('-') > -1) {
        this.value.value = this.value.value.split('-').join('/')
      }
      this.data.value = this.value.value.split(' ').join(' - ').split('/').join(' / ').split(':').join(' : ')
      // setTimeout(() => {
      //   this.validateValue('initial')
      // }, 10)
    },
    handleClearInput () {
      this.data.value = ''
      this.data.isValid = this.isEmpty
      this.$emit('input', {value: '', isValid: this.isEmpty})
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: this.data.value,
          paste: true
        },
        event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    }
  },
  watch: {
    // value: {
    //   handler () {
    //     console.log(this.data.value, this.value.value)
    //     if (this.value.value.split(' ').join(' - ').split('/').join(' / ').split(':').join(' : ') !== this.data.value) {
    //       this.initValues()
    //     }
    //   },
    //   deep: true
    // },
    // 'data.isValid': {
    //   handler () {
    //     this.$emit('input', {value: this.data.value, isValid: this.data.isValid})
    //   }
    // }
  }
}
</script>

<style lang="scss">
.custom-datepicker-input {
  position: relative;
  margin: 15px 0 25px;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border-radius: .5rem;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        top: -10px;
      }
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none !important;
      text-align: right;
      direction: rtl;

      &.has-clear {
        padding: 0 20px;
      }

      &.disabled {
        opacity: 0.75;
      }
    }

    .clearable {
      position: absolute;
      top: calc((100% - 25px) / 2);
      right: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .date-picker-label {
    font-size: 12px;
    position: absolute;
    top: -10px;
    left: 5px;
    padding: 0 5px;
    background-color: #ffffff;
    z-index: 1;
  }

  .vpd-input-group {
    width: 20px !important;
    position: absolute;
    top: 9px;
    left: 6px;

    .vpd-icon-btn {
      opacity: 1 !important;
      height: 25px !important;
      width: 20px !important;
      background-color: inherit !important;
      border: 0 !important;
      padding: 0;

      svg {
        fill: #000000;
        overflow: visible !important;
      }
    }

    .form-control {
      border: 0;
      width: 100%;
      display: none;
      line-height: 35px;
    }
  }

  .invalid-text-helper {
    font-size: 11px;
    color: #b00000;
  }

  &.dir-ltr {
    label {
      input {
        direction: ltr;
        text-align: left;
      }

      .clearable {
        left: 5px;
        right: unset;
      }
    }

    .vpd-input-group {
      right: 6px;
      left: unset;
    }
  }
}
</style>
